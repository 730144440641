<template>
    <div v-click-outside>
        <div
            class="h-btn-md rounded justify-center flex ml-auto px-1.5 bg-blue-light-1 border-blue-light-1 text-blue-dark-1 hover:bg-blue-light-2 hover:border-blue-light-2 active:bg-blue-light-3 active:border-blue-light-3 disabled:bg-gray-faded-1 disabled:border-gray-faded-1 disabled:text-opacity-30 disabled:pointer-events-none"
        >
            <button
                class="flex justify-center items-center text-blue-dark-1 text-md font-semibold"
                @click="toggle"
                data-testid="toggle-button"
            >
                Add New User
                <div class="h-btn-md border-blue-dark-1 border border-b-0 border-opacity-10 mx-1.5" />
                <img src="@/assets/img/icons/arrow-down.svg" />
            </button>
        </div>
        <div class="absolute">
            <div
                class="flex flex-col rounded border border-blue-dark-1 mt-1 relative right-15 bg-white min-w-275"
                :class="visibleDropdown ? '' : 'hidden'"
            >
                <button
                    class="text-left font-medium text-md text-grey-dark-1 px-2 py-1 hover:bg-blue-light-5 hover:text-blue-dark-1 focus:outline-none"
                    @click="addIndividualUser"
                    data-testid="add-button"
                >
                    Add New User Individually
                </button>
                <button
                    class="text-left font-medium text-md text-grey-dark-1 px-2 py-1 hover:bg-blue-light-5 hover:text-blue-dark-1 focus:outline-none"
                    @click="upload"
                    data-testid="upload-button"
                >
                    Upload New Users File (.CSV)
                </button>
                <input
                    type="file"
                    ref="files"
                    @change="handleFileUpload"
                    class="hidden"
                    :key="inputKey"
                    data-testid="file-input"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import _trim from 'lodash/trim';

export default {
    name: 'AddNewButton',
    data: () => ({
        visibleDropdown: false,
        inputKey: 0,
        hasInvalidUsers: false,
        invalidUsersCount: 0,
    }),
    props: {
        callback: { type: Function, default: () => null },
        withRole: { type: Boolean, default: false },
    },
    methods: {
        ...mapActions({
            setUserPreviewList: 'assessmentSetup/setUserPreviewList',
            openModal: 'modals/openModal',
            showError: 'alerts/showError',
        }),
        open() {
            this.openModal({ modal: 'usersPreview' });
        },
        addIndividualUser() {
            this.openModal({
                modal: 'individualUserModal',
                props: { withRole: this.withRole, callback: this.callback },
            });
        },
        handleFileUpload() {
            this.inputKey += 1;
            this.$papa.parse(this.$refs.files.files[0], {
                header: true,
                encoding: "ISO-8859-1",
                complete: async (results) => {
                    let errors = 0;
                    if (results.meta['fields'].indexOf('First Name') < 0) errors += 1;
                    if (results.meta['fields'].indexOf('Last Name') < 0) errors += 1;
                    if (results.meta['fields'].indexOf('Job Title') < 0) errors += 1;
                    if (results.meta['fields'].indexOf('Email') < 0) errors += 1;
                    if (errors === 0) {
                        this.allUsers = results.data
                            .filter(x => Object.keys(x).length === 4)
                            .map((el) => ({
                                firstName: _trim(el['First Name']),
                                lastName: _trim(el['Last Name']),
                                email: _trim(el['Email']),
                                jobTitle: _trim(el['Job Title']),
                            }));
                        this.validUsers = this.allUsers.filter((el) => el.firstName !== '' && el.lastName !== '' && el.email !== '');
                        this.hasInvalidUsers = this.allUsers.length > this.validUsers.length;
                        this.invalidUsersCount = this.allUsers.length - this.validUsers.length;
                        this.setUserPreviewList({ users: this.validUsers });
                        this.openModal({
                            modal: 'usersPreview',
                            props: { 
                                callback: this.callback, 
                                hasInvalidUsers: this.hasInvalidUsers, 
                                invalidUsersCount: this.invalidUsersCount,
                            },
                        });
                    } else {
                        this.showError({
                            messageHeader: 'Upload Error',
                            message:
                                'Please check your file to make sure that it uses the same format as the template provided and upload again.',
                        });
                    }
                },
                error: async (err) => {
                    this.showError({
                        messageHeader: 'Upload Error',
                        message:
                            'Please check your file to make sure that it uses the same format as the template provided and upload again.',
                    });
                },
            });
        },
        upload() {
            this.$refs.files.click();
        },
        closeDropdown() {
            this.visibleDropdown ? (this.visibleDropdown = false) : null;
        },
        setOption(val) {
            this.selectedOption = val;
        },
        toggle() {
            this.visibleDropdown = !this.visibleDropdown;
        },
    },
};
</script>

<style></style>
