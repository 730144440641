<template>
  <div>
    <div class="flex justify-between">
      <h1
        class="text-xl text-grey-light font-medium font-headers mb-3 font-bold text-grey-medium-2"
        data-testid="title-header"
      >
        People /
        <span class="font-bold text-grey-dark-1"> Invite to Platform</span>
      </h1>
      <Button
        text="Next"
        size="medium"
        type="primary"
        :disabled="disableNext"
        :iconRight="arrowRight"
        @click.native="goNext"
      />
    </div>
    <div class="bg-white shadow mt-3 p-3 rounded">
      <p class="font-barlow text-mdl font-bold text-grey-light mb-2">
        Company User Accounts
      </p>
      <div class="xl:w-3/6 w-full">
        <p class="text-mdh text-grey-dark-1">
          Select the people which you want to invite to the platform.
        </p>
      </div>
      <div class="mt-3">
        <NotInvitedPeopleTable data-testid="people-table" ref="people-table" />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../../../components/Button/Button.vue';
import NotInvitedPeopleTable from '../components/NotInvitedPeopleTable';
import arrowRight from '@/assets/img/icons/arrow-right-white.svg';
import { mapActions } from 'vuex';

export default {
  name: 'PeopleInvite',
  components: { NotInvitedPeopleTable, Button },
  data: () => ({ arrowRight, disableNext: false }),
  methods: {
    ...mapActions({
      setSelectedUsersForInvite: 'people/setSelectedUsersForInvite',
    }),
    goNext() {
      this.setSelectedUsersForInvite(this.$refs['people-table'].selectedUsers);
      this.$router.push('/people/invite/send-emails');
    },
  },
};
</script>
