<template>
  <div>
    <div class="flex flex-row items-end justify-between">
      <div class="flex items-center space-x-3  mb-2">
        <InputField
          :placeholder="inputPlaceholder"
          :label="inputLabel"
          :onChange="setKeyword"
          leftIcon
          class="w-inputField"
          data-testid="filter-field"
        />
        <Select
          :options="this.statusOptions"
          :onSelect="setStatusFilter"
          :value="statusFilter"
          :preselectFirst="true"
          label="Status"
          objects
          class="ml-2 min-w-150 h-40"
        />
        <button
          class="flex items-center space-x-2 mt-2"
          @click="toggleShowOnlySelected"
        >
          <Checkbox :active="showOnlySelected" />
          <p class="text-mdh text-grey-dark-1">
            Show only selected users
          </p>
        </button>
      </div>
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} out of {{ this.usersCount }} people
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
        <button class="mr-2" @click="toggleAll">
          <Checkbox :active="selectAll" data-testid="select-all-checkbox" />
        </button>
        <button
          class="flex flex-row items-center focus:outline-none w-tableIndex"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </button>
        <div class="flex flex-grow w-1/5 pr-1">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="() => sort('name', 0)"
            data-testid="header-0"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">
              NAME {{ this.$screen.breakpoint.xlAndDown ? "& EMAIL" : "" }}
            </p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div
          class="flex w-1/6 pr-2 mr-1"
          v-if="!this.$screen.breakpoint.xlAndDown"
        >
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="() => sort('email', 1)"
            data-testid="header-0"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/6 ml-1 pr-1">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('jobTitle', 2)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/10">
          <button 
            class="flex flex-row items-center focus:outline-none"
            @click="sort('status', 3)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
            <img src="../../../../assets/img/delta-table/caret.svg" class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null" />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 360px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="showOnlySelected ? selectedUsers : mappedList"
          :data-component="itemComponent"
          :extra-props="{ toggle: toggleItem }"
          @tobottom="triggerFetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import AddNewButton from "../../../AdminAssessments/components/AddNewButton";
import email from "@/assets/img/icons/email.svg";
import { mapActions, mapState } from "vuex";

export default {
  name: "NotInvitedPeopleTable",
  components: { VirtualList, AddNewButton },
  props: {
    inputLabel: { type: String, default: "Filter by" },
    inputPlaceholder: {
      type: String,
      default: "Search by name, email or job title",
    },
    id: { type: Number, default: 1 },
  },
  data: () => ({
    email,
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    selectAll: false,
    pageNr: 1,
    pageSize: 20,
    sorting: null,
    usersCount: null,
    showOnlySelected: false,
    statusFilter: null,
    statusOptions: [
      { label: "All Statuses", id: -1 },
      { label: "Active", id: 1 },
      { label: "Invited", id: 2 },
      { label: "Not Invited", id: 3 },
    ],
  }),
  computed: {
    ...mapState({
      selectedUsersForInvite: (state) => state.people.selectedUsersForInvite,
    }),
    selectedUsers() {
      return this.mappedList.filter((item) => item.isSelected);
    },
  },
  async mounted() {
    this.setSelectedUsersForInvite([]);
    this.checkSelected();
  },
  watch: {
    keyword() {
      this.showOnlySelected = false;
      this.updateList(true);
    },
    statusFilter() {
      this.showOnlySelected = false;
      this.updateList(true);
      this.selectAll = false;
      this.mappedList.map((item) => (item.isSelected = false));
    },
  },
  methods: {
    ...mapActions({
      getPeople: "people/getPeople",
      addUserToInvite: "people/addUserToInvite",
      removeUserFromInvite: "people/removeUserFromInvite",
      setSelectedUsersForInvite: "people/setSelectedUsersForInvite",
    }),
    async sort(key, index) {
      this.resetRotationMatrix(index);
      this.setRotation(index);
      switch (key) {
        case "name":
          this.sorting = { firstName: this.rotationMatrix[0] == -1 ? 1 : 2 }
          break;
        case "email":
          this.sorting = { email: this.rotationMatrix[1] == -1 ? 1 : 2 }
          break;
        case "jobTitle":
          this.sorting = { jobTitle: this.rotationMatrix[2] == -1 ? 1 : 2 }
        case "status":
          this.sorting = { status: this.rotationMatrix[3] == -1 ? 1 : 2 }
          break;
        default:
          break;
      }
      this.updateList(true)
    },
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    setRotation(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx == index ? -1 * item : 1
      );
    },
    mapSelected() {
      if (this.selectedUsersForInvite.length > 0) {
        this.selectedUsersForInvite.forEach((item) => {
          let index = this.mappedList.findIndex((el) => el.id === item.id);
          if (index > -1) {
            this.mappedList[index].isSelected = true;
          }
        });
      }
    },
    setKeyword: debounce(function (value) {
      this.keyword = value;
      this.pageNr = 1;
    }, 500),
    setStatusFilter(value) {
      this.statusFilter = value;
    },
    triggerFetch() {
      if (this.pageNr < Math.ceil(this.usersCount / 10)) {
        this.pageNr++;
        this.selectAll ? this.toggleAll() : null;
        this.updateList();
      }
    },
    async updateList(reset) {
      if (reset) {
        this.mappedList = [];
        this.pageNr = 1;
      }
      let newContent = await this.getPeople({
        keyword: this.keyword,
        pageNr: this.pageNr,
        sorting: this.sorting,
        allowInviteInactiveUsers: false,
        status: this.statusFilter.id,
        pageSize: this.pageSize
      }).then((rsp) => {
        this.usersCount = rsp.data.totalCount;
        return rsp.data.data;
      });
      let map = newContent.map((item, idx) => ({
        ...item,
        isSelected: false,
        index: (this.pageNr - 1) * 10 + idx,
      }));
      this.mappedList = [...this.mappedList, ...map];
      this.mapSelected();
    },
    checkSelected() {
      var count = 0;
      this.mappedList.forEach((element) => {
        if (element.isSelected) count++;
      });
      if (count === 0) this.$parent.disableNext = true;
      else this.$parent.disableNext = false;
    },
    toggleItem(index, value) {
      if (value) {
        this.addUserToInvite(this.mappedList[index]);
      } else {
        this.removeUserFromInvite(this.mappedList[index]);
      }
      this.mappedList[index].isSelected = !this.mappedList[index].isSelected;
      this.checkSelected();
    },
    async fetchAllUsers(allSelected) {
      this.pageNr = Math.ceil(this.usersCount / 10);
      let newContent = await this.getPeople({
        keyword: this.keyword,
        pageNr: 1,
        sorting: this.sorting,
        allowInviteInactiveUsers: false,
        pageSize: this.usersCount,
        status: this.statusFilter.id,
      }).then((rsp) => {
        this.usersCount = rsp.data.totalCount;
        return rsp.data.data;
      });
      let peopleList = newContent.map((item, idx) => ({
        ...item,
        isSelected: allSelected,
        index: idx,
      }));
      this.mappedList = [...peopleList];
    },
    toggleAll() {
      if (this.pageNr !== Math.ceil(this.usersCount / 10)) {
        this.fetchAllUsers(true);
      }
      this.selectAll = !this.selectAll;
      this.mappedList.map((item) => (item.isSelected = this.selectAll));
      this.checkSelected();
    },
    toggleShowOnlySelected() {
      this.showOnlySelected = !this.showOnlySelected;
    },
  },
};
</script>
