<template>
  <div>
    <div class="flex flex-row w-full px-2 items-center">
      <button class="mr-2" @click="toggle(index, !source.isSelected)" data-testid="toggle-checkbox">
        <Checkbox :active="source.isSelected" />
      </button>
      <p
        class="flex font-normal text-md text-grey-dark-2 w-tableIndex justify-center pr-3"
      >
        {{ index + 1 }}
      </p>
      <div class="flex w-1/5 pr-1 flex-grow flex-col py-2">
        <p
          v-clamp="$screen.breakpoint.xxxlOnly ? '35' : '30'"
          class="text-md text-grey-dark-1 font-semibold"
        >
          {{ fullName }}
        </p>
        <p
          class="text-sm pt-0.5 text-grey-dark-3 font-normal"
          v-if="this.$screen.breakpoint.xlAndDown"
        >
          {{ source.email }}
        </p>
      </div>
      <div
        class="flex w-1/6 pr-1 mr-1 py-2"
        v-if="!this.$screen.breakpoint.xlAndDown"
      >
        <p
          class="text-md text-grey-dark-2"
          v-clamp="$screen.breakpoint.xxxlOnly ? '25' : '20'"
        >
          {{ source.email }}
        </p>
      </div>
      <div class="flex flex-grow w-1/6 ml-1 pr-1 py-2">
        <p
          v-clamp="$screen.breakpoint.xxxlOnly ? '30' : '20'"
          class="text-md text-grey-dark-2"
        >
          {{ source.jobTitle }}
        </p>
      </div>
      <div class="flex flex-grow w-1/10 px-2  py-2">
        <p class="text-md" :class="statusColor">
          {{ status }}
        </p>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import _forEach from 'lodash/forEach';

export default {
  name: 'ListItem',
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    toggle: { type: Function },
  },
  computed: {
    fullName() {
      return this.source.firstName + ' ' + this.source.lastName;
    },
    status() {
      switch (this.source.status.toString()) {
        case '0':
          return 'Inactive';
        case '1':
          return 'Active';
        case '2':
          return 'Invited';
        case '3':
          return 'Not invited';
        default:
          break;
      }
    },
    statusColor() {
      switch (this.source.status.toString()) {
        case '0':
          return 'text-red';
        case '1':
          return 'text-green-2';
        case '2':
          return 'text-yellow-medium';
        case '3':
          return 'text-grey-medium-2';
        default:
          break;
      }
    },
  },
};
</script>
